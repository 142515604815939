input::placeholder,
textarea::placeholder {
  font-size: 1.5rem;
}

/* Hide the default checkbox */
input[type="checkbox"].circle-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

/* Add checkmark for selected state */
input[type="checkbox"].circle-checkbox:checked {
  background-color: #3c4339;
  border-color: #3c4339;
}

input[type="checkbox"].circle-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
}

input[type="text"],
textarea {
  border: 2px solid black;
  outline: none;
  background: none;
  box-shadow: none;
  -webkit-appearance: none; /* For some browsers (e.g., Safari) */
  -moz-appearance: none; /* For Firefox */
  appearance: none; /* General reset */
  font-size: 1.5rem;
}

.contactWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 3fr;
  padding: 7rem 40px 0 40px;
  background: #f2ede1;
  max-height: 100vh;
  height: 100vh;
}

form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 2fr;
  grid-column: 2 / span 1;
  grid-row: 1 / span 2;
  background: #e9dfc5;
  border-radius: 1.5rem;
  max-height: 80vh;
  height: 80vh;
  padding: 1.5rem;
}

.contactInfoWrapper {
  display: grid;
  grid-column: 1 / span 2;
  grid-row: 1 / span 1;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.contactInfoWrapper label {
  font-size: 1.5rem;
  color: black;
  font-weight: 700;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}

.contactInfoWrapper #firstName {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 2rem;
  padding: 1rem;
}

.contactInfoWrapper #lastName {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  margin-bottom: 1rem;
  border-radius: 2rem;
  padding: 1rem;
}

.contactInfoWrapper #phoneNumber {
  grid-column: 2 / span 1;
  grid-row: 3 / span 1;
  margin-bottom: 1rem;
  border-radius: 2rem;
  padding: 1rem;
}

.contactInfoWrapper #email {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 2rem;
  padding: 1rem;
}

.serviceInterestWrapper {
  display: grid;
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
  grid-template-columns: 1;
  grid-template-rows: 1fr 3fr;
  height: 100%;
  width: 100%;
}

#serviceLabel {
  font-size: 1.5rem;
  color: black;
  font-weight: 700;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}

#serviceOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  border: 2px solid #3c4339;
  font-size: 1.5rem;
  height: 3rem;
  padding: 1rem;
  margin: 0.5rem;
}

.option label {
  margin-left: 1rem;
  justify-self: center;
  margin-bottom: 0;
}

.extraWrapper {
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 2;
  grid-row: 3 / span 1;
}

.extraWrapper textarea {
  height: 90%;
  border-radius: 20px;
  padding: 1rem;
}

.extraWrapper label {
  font-size: 1.5rem;
  color: black;
  font-weight: 700;
}

.contactWrapper h2 {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  font-size: 7rem;
  font-weight: 700;
  color: #3c4339;
}

.contactWrapper p {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  font-size: 2rem;
  font-weight: 400;
  color: #3c4339;
  width: 80%;
}

#submitButton {
  width: 12rem;
  margin: 1rem;
  align-self: end;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 3000px;
  height: 4rem;
  color: white;
  background: #122d18;
}

@media screen and (min-width: 0px) and (max-width: 1600px) {
  .contactWrapper h2 {
    font-size: 5rem;
  }

  .contactWrapper p {
    font-size: 2rem;
    width: 90%;
  }

  form {
    max-height: 70vh;
    height: 70vh;
  }

  .contactInfoWrapper label {
    align-self: center;
  }

  .contactInfoWrapper #firstName {
    width: 95%;
  }

  .contactInfoWrapper #lastName {
    width: 95%;
  }

  .contactInfoWrapper #phoneNumber {
    width: 95%;
  }

  .contactInfoWrapper #email {
    width: 95%;
  }

  #serviceLabel {
    align-self: center;
  }

  .contactWrapper {
    max-height: 110vh;
    height: 110vh;
  }

  #submitButton {
    width: 10rem;
    margin: 0.5rem;
    height: 5rem;
  }
}

/* MOBILE SIZING */
@media screen and (min-width: 0px) and (max-width: 1200px) {
  form {
    max-height: 95vh;
    height: auto;
    margin-bottom: 2rem;
  }

  label {
    font-size: 1rem !important;
  }

  input::placeholder,
  textarea::placeholder {
    font-size: 1rem !important;
  }

  .contactWrapper h2 {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .contactWrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 150vh;
    padding: 5rem 2.5rem 0 2.5rem;
  }

  .contactWrapper p {
    font-size: 1.25rem;
  }

  .contactInfoWrapper #firstName {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 2rem;
    padding: 0.5rem;
  }

  .contactInfoWrapper #lastName {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    margin-bottom: 0.5rem;
    border-radius: 2rem;
    padding: 0.5rem;
  }

  .contactInfoWrapper #phoneNumber {
    grid-column: 2 / span 1;
    grid-row: 3 / span 1;
    margin-bottom: 0.5rem;
    border-radius: 2rem;
    padding: 0.5rem;
  }

  .contactInfoWrapper #email {
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 2rem;
    padding: 0.5rem;
  }

  .serviceInterestWrapper {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
    grid-row: 2 / span 1;
    height: 100%;
    width: 100%;
  }

  .contactInfoWrapper {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
  }

  .contactInfoWrapper label {
    align-self: start;
  }
}
