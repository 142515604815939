.servicesWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 2fr 3fr 2fr;
  padding: 5rem 40px 0 40px;
  background: #f2ede1;
  max-height: 100vh;
  height: 100vh;
}

.servicesWrapper h2 {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  font-size: 7rem;
  font-weight: 700;
  color: #3c4339;
  margin-top: 3rem;
  margin-left: 1rem;
}

.servicesWrapper p {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  font-size: 2rem;
  font-weight: 400;
  color: #3c4339;
  width: 80%;
  margin-left: 1rem;
}

.servicesWrapper button {
  grid-column: 2 / span 1;
  grid-row: 4 / span 1;
  background-color: #122d18;
  color: #f2ede1;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 3000px;
  padding: 1rem 0.5rem 1rem 0.5rem;
  height: 6rem;
  width: 18rem;
  font-size: 1.5rem;
  font-weight: 300;
}

.servicesCarousel {
  grid-column: 1 / span 1;
  grid-row: 1 / span 4;
  width: auto;
  height: 100%;
  margin-top: 2rem;
}

.carousel-inner {
  height: auto;
}

.servicesWrapper img {
  border-radius: 30px;
  width: 90%;
  height: auto;
  object-fit: cover;
  align-self: center;
  margin: 0 auto 0 auto;
}

.servicesWrapper ul {
  grid-column: 2 / span 1;
  grid-row: 3 / span 1;
  font-size: 2.25rem;
  font-weight: 400;
  color: #3c4339;
  margin-left: 1rem;
}

.servicesWrapper li {
  margin-bottom: 2rem;
}

@media screen and (min-width: 1600px) and (max-width: 1850px) {
  .servicesWrapper p {
    font-size: 1.5rem;
    width: 100%;
  }
}

@media screen and (min-width: 0px) and (max-width: 1600px) {
  .servicesWrapper img {
    width: auto;
    height: 90%;
    object-fit: cover;
    margin-right: 1rem;
  }

  .servicesWrapper p {
    font-size: 1.75rem;
    width: 90%;
    margin-left: 1rem;
  }

  .servicesWrapper ul {
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  .servicesWrapper li {
    margin-bottom: 1.5rem;
  }

  .servicesWrapper h2 {
    font-size: 5rem;
    margin-top: 3rem;
    margin-left: 1rem;
  }

  .servicesWrapper {
    max-height: 110vh;
    height: 110vh;
  }
}

/* MOBILE SIZING */
@media screen and (min-width: 0px) and (max-width: 1200px) {
  .servicesWrapper h2 {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .servicesWrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: none;
  }

  .servicesWrapper button {
    margin: 0 0 1rem 0;
  }

  .servicesWrapper p {
    font-size: 1.25rem;
  }

  .servicesWrapper li {
    margin-bottom: 0.5rem;
  }

  .servicesWrapper img {
    width: 100%;
  }
}
