@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

/* BEGIN REUSABLE CLASSES */
.btn-style {
  background-color: #ffc364;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 3000px;
  padding: 1rem 0.5rem 1rem 0.5rem;
  width: 150px;
  cursor: pointer;
}
/* END REUSABLE CLASSES */

/* BEGIN DEFAULT CSS */
html,
body {
  overflow-x: hidden;
  padding-top: 1rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  background-color: #122d18;
  font-family: "Nunito", sans-serif;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

* {
  /*outline: 1px solid #f00 !important;*/
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root,
#__next {
  isolation: isolate;
}
/* END DEFAULT CSS */

.squareMask {
  clip-path: inset(0);
}

.bodyWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AppWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropdownNavButton {
  display: none;
}

.nav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #f2ede1;
  z-index: 20;
}

.nav img {
  grid-column: 1 / span 1;
  width: 5rem;
  clip-path: circle(50%);
}

/*
.navImageDiv {
  display: inline-block;
  border-radius: 3000px;
  background: #5d3e17;
}
*/
/*
.nav p {
  grid-column: 1 / span 1;
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 0 0 !important;
}
*/

#navLogo:hover {
  text-decoration: none;
}

.navButtons {
  grid-column: 2 / span 1;
  justify-self: center;
  align-self: center;
}

.navButtons a {
  padding-right: 20px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  align-self: center;
}

.contactUsButtonDiv {
  grid-column: 3 / span 1;
  text-align: right;
  padding: 0 0 0 0 !important;
  align-content: center;
}

.contactUsButtonDiv a {
  text-decoration: none;
  color: #122d18;
}

.hero {
  display: grid;
  /*max-width: 1600px;*/
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto auto;
  margin-top: 120px;
  margin-bottom: 7rem;
  color: #f2ede1;
  padding: 0 1.5rem 0 1.5rem;
}

.hero h1 {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  font-size: 10rem;
  line-height: 10rem;
  padding-right: 20px;
  align-self: end;
  font-weight: 700;
}

.hero p {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  font-size: 2rem;
  padding-right: 20px;
  align-self: center;
  font-weight: 400;
}

.heroButtonDiv {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
}

.heroButtonDiv a {
  text-decoration: none;
  color: #122d18;
}

.heroImg {
  grid-column: 2 / span 1;
  grid-row: 1 / span 3;
  width: auto;
  height: 80vh;
  object-fit: contain;
  justify-self: center;
  max-width: 100%;
  border-radius: 25px;
}

.hero button {
  font-size: 1.5rem;
  height: 5rem;
  width: 15rem !important;
}

.services {
  margin-top: 0rem;
  width: 100%;
  padding: 0 1.5rem 0 1.5rem;
  /*max-width: 1600px;*/
}

.services h5 {
  font-size: 1.5rem;
  padding-bottom: 0.5rem;
  color: #f2ede1;
  font-weight: 400;
}

.services h2 {
  font-size: 6rem;
  line-height: 6rem;
  color: #f2ede1;
  font-weight: 700;
  padding-bottom: 2.5rem;
  width: 80%;
}

.center {
  background: #f2ede1;
  /*max-width: 1600px;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f2ede1;
}

.servicesContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 2.5rem;
  padding-bottom: 6rem;
}

.serviceCard {
  display: flex;
  flex: 1; /* ensures each item takes up 1 part of the space equally */
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 50vh;
  margin: 0 1rem 0 1rem;
}

.serviceCard img {
  padding-top: 1.5rem;
  padding-bottom: 2.25rem;
  width: 5rem;
}

.serviceCard p {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 400;
}

.serviceCard h6 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  padding-bottom: 2.25rem;
}

.serviceOne {
  background: #ffc364;
}

.serviceTwo {
  background: #e9dfc5;
}

.serviceThree {
  background: #acbf88;
}

.serviceFour {
  background: #fc8e3e;
}

.whoItsFor {
  width: 100%;
  background: #fc8e3e;
  padding-left: 20px;
  padding-top: 20px;
  height: 329px;
}

.whoItsFor h5 {
  font-size: 1.5rem;
  font-weight: 400;
}

.whoItsFor h3 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 40px;
}

.whoItsFor p {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 400;
  padding-top: 20px;
}

.why {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 97%;
  border-radius: 20px;
  background: #e9dfc5;
  display: grid;
  margin-top: 6rem;
  padding-left: 20px;
}

.why h5 {
  font-size: 2rem;
  font-weight: 400;
}

.whyAbout {
  text-decoration: underline;
  padding-top: 40px;
  font-size: 1rem;
}

.whyAbout a {
  color: inherit;
  font-size: 1.5rem;
}

.why h3 {
  font-size: 4rem;
  font-weight: 700;
}

.whyCard {
  display: flex;
  flex-direction: column;
  height: 175px;
  max-width: 450px;
  margin-bottom: 4rem;
}

.whyCard img {
  margin-bottom: 1rem;
  width: 50px;
}

.whyCard h3 {
  font-size: 1.5rem;
  font-weight: 700;
}

.whyCard p {
  font-size: 1.25rem;
  font-weight: 400;
}

.why .growCard {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  padding-top: 60px;
}

.why .collabCard {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  padding-top: 60px;
}

.why .communityCard {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  margin-top: 20px;
}

.why .promoteCard {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
  margin-top: 20px;
}

.whyText {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  padding-top: 60px;
  max-width: 80%;
}

.difference {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 3fr;
  margin-top: 6rem;
  margin-right: 20px;
  margin-left: 20px;
}

.differenceText {
  display: flex;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  flex-direction: column;
  margin-top: auto;
  margin-left: 20px;
}

.differenceText h5 {
  font-size: 1.5rem;
  font-weight: 400;
}

.differenceCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.differenceCard h4 {
  padding-bottom: 20px;
  font-size: 3rem;
  font-weight: 700;
  max-width: 50%;
}

.differenceCard p {
  padding-bottom: 40px;
  font-size: 2rem;
  font-weight: 400;
}

.volunteerCard {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  background: #ffc364;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
}

.donationCard {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  background: #acbf88;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
}

.difference h5 {
  margin: 0;
  font-size: 2rem;
  font-weight: 400;
}

.difference h3 {
  font-size: 3.5rem;
  font-weight: 700;
}

.difference button {
  background-color: #122d18 !important;
  color: white !important;
}

.loveText {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.love {
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  margin: 40px;
}

.loveCarousel {
  position: relative;
  border-radius: 20px;
  background: #e9dfc5;
  width: 100%;
  height: 50vh;
}

.carousel .carousel-inner {
  height: 100%;
  align-content: center;
}

.carousel-indicators {
  position: absolute;
}

.carousel-indicators li {
  background-color: #b1b1b1;
  width: 3rem;
  height: 0.25rem;
}

.carousel-indicators .active {
  background-color: black;
}

.carousel-control-next,
.carousel-control-prev {
  filter: invert(100%);
}

.loveCarouselCard {
  margin: auto auto auto auto;
}

.loveCardInner {
  width: 50%;
  display: grid;
  margin: 10rem auto 10rem auto;
  grid-template-columns: 1fr 9fr;
  grid-template-rows: 3fr 1fr;
}

.loveCarouselCard p {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.25rem;
  text-align: center;
  align-self: center;
}

.loveCarouselCard h6 {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  text-align: center;
}

.loveCardImg {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  width: 40px;
  justify-self: end;
}

.love h5 {
  margin: 0;
  font-size: 2rem;
  font-weight: 400;
}

.love h3 {
  font-size: 3.5rem;
  font-weight: 700;
}

.work {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 3fr;
  width: 97%;
  border-radius: 20px;
  background: #f2ede1;
  display: grid;
  margin-top: 0rem;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.workText {
  display: flex;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  flex-direction: column;
  margin-top: auto;
  margin-left: 20px;
}

.workText h5 {
  margin: 0;
  font-size: 2rem;
  font-weight: 400;
}

.workText h3 {
  font-size: 3.5rem;
  font-weight: 700;
}

.igCardHolder {
  display: flex;
  grid-column: 1 / span 3;
  grid-row: 2 / span 1;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.igCard {
  flex: 1;
  margin: 0 1rem 0 1rem;
}

#attribution {
  text-decoration: none;
  color: white !important;
  font-size: 0.5rem;
}

footer {
  padding-top: 2rem;
  color: #f2ede1;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr;
  overflow: hidden;
  padding: 2rem;
}

#footerBlock {
  display: flex;
  flex-direction: column;
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  justify-content: space-between;
}

#footerBlockBottom {
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  justify-content: space-between;
}

footer img {
  width: 50%;
  justify-self: center;
  border-radius: 3000px;
}

footer h2 {
  font-size: 5rem;
  font-weight: 700;
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
  width: 70%;
  color: #f2ede1;
}

footer h6 {
  align-self: end;
  font-size: 1.5rem;
  font-weight: 200;
  align-self: start;
}

footer button {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  font-size: 1.5rem;
  height: 5rem;
  width: 15rem !important;
}

#footerButtonLink {
  color: black;
}

#footerButtonLink:hover {
  text-decoration: none;
}

footer ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.leftFooterUl {
  grid-column: 1 / span 1;
}

.rightFooterUl {
  grid-column: 2 / span 1;
}

footer li {
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: 0rem;
  color: white;
}

@media screen and (min-width: 1600px) and (max-width: 1850px) {
  .differenceCard h4 {
    font-size: 2rem;
  }

  .differenceCard p {
    font-size: 1.5rem;
  }

  .serviceCard p {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 1600px) {
  .hero {
    margin-top: 2rem;
  }

  .hero h1 {
    font-size: 5rem;
    line-height: 5rem;
  }

  .serviceCard img {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    width: 3rem;
  }

  .serviceCard p {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 400;
  }

  .whyAbout {
    margin-bottom: 2rem;
  }

  .why h3 {
    font-size: 4rem;
    font-weight: 700;
  }

  .whyCard h3 {
    font-size: 1.5rem;
  }

  .whyText {
    grid-row: 1 / span 2;
  }

  .differenceCard h4 {
    font-size: 2rem;
  }

  .differenceCard p {
    padding-bottom: 20px;
    font-size: 1.5rem;
  }
}

/* MOBILE SIZING */
@media screen and (min-width: 0px) and (max-width: 1200px) {
  .bodyWrapper {
    margin-top: 5rem;
  }

  p {
    font-size: 1rem !important;
  }

  h4 {
    font-size: 1.5rem !important;
  }

  h3 {
    font-size: 2rem !important;
  }

  h5 {
    font-size: 1rem !important;
  }

  .btn-style {
    width: 100px;
  }

  .dropdownNavButton {
    display: block;
    grid-column: 3 / span 1;
    justify-self: end;
  }

  #dropdownMenuButton {
    background: none;
    border-radius: none;
    box-shadow: none;
    border: none;
  }

  .dropdown-menu {
    background: #3c4339;
  }

  .dropdown-item {
    color: white;
  }

  .contactUsButtonDiv {
    display: none;
  }

  .nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .nav img {
    align-self: center;
    width: 3rem;
  }

  /*
  .nav p {
    font-size: 1.25rem;
    align-self: center;
  }
  */
  .navButtons {
    display: none;
  }

  .hero {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr 1fr 1fr;
    padding: 0 1.5rem 0 1.5rem;
    margin: 1rem 0 0 0;
  }

  .hero h1 {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
    font-size: 2.5rem;
    line-height: 2.5rem;
    padding-right: 20px;
    align-self: end;
    font-weight: 700;
    margin-bottom: 0;
    width: 100%;
  }

  .hero p {
    grid-column: 1 / span 2;
    grid-row: 3 / span 1;
    font-size: 1.5rem;
    padding-right: 20px;
    align-self: start;
    font-weight: 400;
    margin-top: 1rem;
  }

  .heroButtonDiv {
    grid-column: 1 / span 1;
    grid-row: 4 / span 1;
    margin-top: 2rem;
    align-self: start;
  }

  .heroButtonDiv a {
    text-decoration: none;
    color: #122d18;
  }

  .heroImg {
    grid-column: 1 / span 2;
    grid-row: 2 / span 1;
    width: 100%;
    object-fit: contain;
    justify-self: start;
    height: auto;
    margin-top: 1rem;
  }

  .hero button {
    font-size: 1rem;
    height: 3.3rem;
    width: 10rem !important;
  }

  .servicesContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 2.5rem;
    padding-bottom: 6rem;
  }

  .serviceCard {
    display: flex;
    flex: 1; /* ensures each item takes up 1 part of the space equally */
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    height: auto;
    margin: 1rem 1rem 1rem 1rem;
  }

  .serviceCard img {
    padding-top: 1.5rem;
    padding-bottom: 2.25rem;
    width: 5rem;
  }

  .serviceCard p {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 400;
  }

  .serviceCard h6 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    padding-bottom: 2.25rem;
  }

  .services h5 {
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
    color: #f2ede1;
    font-weight: 400;
  }

  .services h2 {
    font-size: 2rem;
    line-height: 2rem;
    color: #f2ede1;
    font-weight: 700;
    padding-bottom: 2.5rem;
  }

  .services {
    margin-top: 2rem;
  }

  .whoItsFor {
    width: 100%;
    background: #fc8e3e;
    padding-left: 20px;
    padding-top: 20px;
    height: 329px;
  }

  .whoItsFor h5 {
    font-size: 1.5rem;
    font-weight: 400;
  }

  .whoItsFor h3 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 40px;
  }

  .whoItsFor p {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 400;
    padding-top: 20px;
  }

  .why {
    width: 90%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    margin: 6rem 2rem 0 2rem;
    padding-left: 20px;
    height: auto;
  }

  .why h5 {
    font-size: 1.5rem;
    font-weight: 400;
    padding-top: 0;
  }

  .whyAbout {
    text-decoration: underline;
    padding-top: 40px;
    font-size: 1rem;
  }

  .whyAbout a {
    font-size: 1rem;
  }

  .why h3 {
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 700;
  }

  .whyCard {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    height: auto;
    max-width: 450px;
  }

  .whyCard img {
    width: 50px;
  }

  .whyCard h3 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .whyCard p {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .why .growCard {
    padding-top: 0px;
  }

  .why .collabCard {
    padding-top: 0px;
  }

  .whyText {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    padding-top: 60px;
    max-width: 80%;
  }

  .difference {
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
    margin-right: 20px;
    margin-left: 20px;
  }

  .differenceText {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-left: 20px;
  }

  .differenceText h5 {
    font-size: 1.5rem;
    font-weight: 400;
  }

  .differenceCard {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
  }

  .differenceCard h4 {
    padding-bottom: 20px;
    font-size: 2rem;
    font-weight: 700;
    max-width: 100%;
  }

  .differenceCard p {
    padding-bottom: 40px;
    font-size: 1.5rem;
    font-weight: 400;
  }

  .volunteerCard {
    background: #ffc364;
    border-radius: 20px;
    padding: 20px;
    margin: 20px;
  }

  .donationCard {
    background: #acbf88;
    border-radius: 20px;
    padding: 20px;
    margin: 20px;
  }

  .difference h5 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
  }

  .difference h3 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .difference button {
    background-color: #122d18 !important;
    color: white !important;
  }

  .loveCarouselCard p {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  .loveCarousel {
    position: relative;
    border-radius: 20px;
    background: #e9dfc5;
    width: 100%;
    height: auto;
  }

  .loveCardInner {
    width: 70%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 9fr;
    grid-template-rows: 3fr 1fr;
    align-self: center;
    justify-self: center;
  }

  .loveCardInnerWrapper {
    width: 100%;
    height: 100%;
  }

  .love h5 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
  }

  .love h3 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .workText {
    display: flex;
    grid-column: 1 / span 3;
    grid-row: 1 / span 1;
    flex-direction: column;
    margin-top: auto;
    margin-left: 20px;
  }

  .workText h5 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
  }

  .workText h3 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  footer h2 {
    font-size: 1.5rem;
  }

  footer ul {
    display: flex;
    flex-direction: row;
  }

  #footerBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #footerBlockBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .leftFooterUl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  footer ul li {
    font-size: 1rem;
    margin: 0.25rem;
  }

  footer img {
    width: 4rem;
  }

  footer button {
    width: 8rem !important;
    height: 3rem !important;
    font-size: 1rem;
  }

  footer h6 {
    padding: 0 0 0 0;
    font-size: 0.5rem;
    font-weight: 200;
  }

  .servicesContent img {
    width: 3rem;
  }
}
