.aboutWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 5fr 2fr;
  padding: 5rem 40px 0 40px;
  background: #f2ede1;
  max-height: 90vh;
  height: 90vh;
}

.aboutWrapper h2 {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  font-size: 7rem;
  font-weight: 700;
  color: #3c4339;
  margin-top: 3rem;
}

.aboutWrapper p {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  font-size: 2rem;
  font-weight: 400;
  color: #3c4339;
  width: 80%;
}

.aboutWrapper button {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  background-color: #122d18;
  color: #f2ede1;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 3000px;
  padding: 1rem 0.5rem 1rem 0.5rem;
  height: 6rem;
  width: 18rem;
  font-size: 1.5rem;
  font-weight: 300;
}

.aboutWrapper img {
  grid-column: 2 / span 1;
  grid-row: 1 / span 3;
  height: 90%;
  width: auto;
  object-fit: cover;
  margin: 2rem auto 0 auto;
  border-radius: 30px;
}

@media screen and (min-width: 0px) and (max-width: 1600px) {
  .aboutWrapper h2 {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    font-size: 5rem;
    font-weight: 700;
    color: #3c4339;
    margin-top: 3rem;
  }

  .aboutWrapper p {
    font-size: 1.75rem;
    width: 90%;
    margin-top: 1rem;
  }

  .aboutWrapper img {
    grid-column: 2 / span 1;
    grid-row: 1 / span 3;
    height: auto;
    width: 100%;
    object-fit: cover;
    margin: 2rem auto 0 auto;
    border-radius: 30px;
  }
}

/* MOBILE SIZING */
@media screen and (min-width: 0px) and (max-width: 1200px) {
  .aboutWrapper h2 {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .aboutWrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: none;
  }

  .aboutWrapper button {
    margin: 0 0 1rem 0;
  }

  .aboutWrapper p {
    font-size: 1.25rem;
  }
}
